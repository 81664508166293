<script>
import HallForm from "@/views/menu/hall/hallForm";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";

@Component({
  inject: {
    hallService: "hallService",
  },
})
export default class HallDetail extends mixins(HallForm) {
  formId = "detail-hall-form";

  get decoratedElements() {
    return this.insertAfter(this.allReadonlyFields, {
      id: "entityLink",
      label: this.translations.labels.common_form_entity_link,
      type: "text",
      readonly: true,
      copyToClipboard: true,
    });
  }

  get fieldColumns() {
    return 2;
  }

  getFormTitle() {
    return this.translations.pageTitles.halls_detail;
  }

  async afterCreate() {
    this.model = await this.hallService.read(this.$route.params.id);
    this.model.entityLink = "Not yet generated";
    this.isReady = true;

    this.model.entityLink = Utility.generateEntityLink(this?.event, "Hall", this.model.id);
  }
}
</script>
